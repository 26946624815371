import { ReactComponent as MDLogo } from "../../assets/math-logo.svg";
import { Box } from "@mui/material";
import { ReactComponent as EDLogo } from "../../assets/english-logo.svg";

import { Link } from "react-router-dom";
import config from "config";
import { ReactComponent as SDLogo } from "../../assets/science-logo.svg";

export const DynamicLogo = ({sx}) => {
    let Logo = MDLogo;
    if(config['class-name'] === 'english') {
        Logo = EDLogo;
    } else if (config['class-name'] === 'science') {
        Logo = SDLogo;
    }
    return (
        <Box component="div"
        sx={{
            width: 40,
            height: 40,
          display: 'inline-flex',
          ...sx,
        }}>
        <Link to="/">
          <Logo style={{ height: "75px" }} />
        </Link>
        </Box>
    )
}

