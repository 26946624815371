import React from "react"
import { Helmet } from "react-helmet-async";

const title: {[key: string]: string} = {
    'science': "Science Teacher",
    'math': "Maths Teacher",
    'english': "English Teacher",
}
 const Title = ({text}: {text: string}) => {
    const version = process.env.REACT_APP_VERSION || 'science'; // Set a default value if REACT_APP_VERSION is undefined
    return (
        
      <Helmet>
    <title> {text} | {title[version]} </title>
    </Helmet>
        )
}

export default Title