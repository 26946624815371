import React from 'react';
import { FormLabel, Slider, FormControl,} from '@mui/material';

const DistanceSlider = (props) => {
  const { distance, setDistance } = props

  // ----------- distance -----------
  const setNewDistance = (_, value) => {
    setDistance(value)
  }

  const formLabel = (label, subLabel = '') => (<FormLabel style={{ color: 'black' }}><strong>{label}</strong>{subLabel}</FormLabel>)

  return (
    <FormControl fullWidth>
      {formLabel(`Distance`, ` : Within ${distance} Miles`)}
      <Slider
        aria-label="distance"
        valueLabelDisplay="auto"
        step={1}
        marks
        min={0}
        max={30}
        value={distance}
        onChange={setNewDistance}
      />
    </FormControl>
  );
};

export default DistanceSlider;
