import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import React from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
// mock
import { API_URL } from '../../api';
import Cookies from 'universal-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { setContacts } from 'src/store/data/dataReducer';

export const DeleteModal = ({ deleteModal, handleDeleteModal, deleteId}) => {
  const { contacts } = useSelector(state => state.data)
  const dispatch = useDispatch()
  // ** Function to delete single role
  const deleteContact = async () => {
    await axios
      .delete(`${API_URL}/api/contacts/${deleteId}`)
      .then((res) => {
        dispatch(setContacts(contacts.filter(contact => contact._id === deleteId)))
        handleDeleteModal()
      }
      ).catch(err => {
        console.log(err);
        toast.error(err.response?.data?.message || 'Contact Delete failed.')
      });
  };

  return (
    <Dialog open={deleteModal}>
      <DialogTitle>Delete Contact</DialogTitle>
      <DialogContent dividers>
        <Typography variant="subtitle1">Are you sure? </Typography>
        <Typography variant="subtitle1">Once deleted data cannot be recovered.</Typography>
      </DialogContent>

      <DialogActions>
        <Button
          variant="contained"
          type="submit"
          style={{ fontFamily: 'Open Sans', minWidth: '90px' }}
          onClick={deleteContact}
        >
          Confirm
        </Button>
        <Button
          variant="contained"
          type="submit"
          style={{ fontFamily: 'Open Sans', minWidth: '90px' }}
          onClick={handleDeleteModal}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};
