import axios from 'axios';
import { toast } from 'react-toastify';
import API_URL from 'src/api';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const fetchUsers = createAsyncThunk('data/fetchUsers', async ({ setLoadingUsers },  {} ) => {
  try {
    setLoadingUsers(true)
    const response = await axios.get(`${API_URL}/api/auth/all`)
    setLoadingUsers(false)
    return response.data
  } catch (error) {
    console.error(error);
    setLoadingUsers(false)
    toast.error(err.response?.data?.message || 'Users fetch failed.')
  }
})

export const fetchProfiles = createAsyncThunk('data/fetchProfiles', async ({ setLoadingProfiles }, {} ) => {
  try {
    setLoadingProfiles(true)
    const response = await axios.get(`${API_URL}/api/teachers/all`)
    setLoadingProfiles(false)
    return response.data
  } catch (error) {
    console.error(error);
    setLoadingProfiles(false)
    toast.error(err.response?.data?.message || 'Profiles fetch failed.')
  }
})

export const fetchPayments = createAsyncThunk('data/fetchPayments', async () => {
  try {
    const response = await axios.get(`${API_URL}/api/billings/all`)
    return response.data
  } catch (error) {
    console.error(error);
    toast.error(err.response?.data?.message || 'Payments fetch failed.')
  }
})

export const fetchContacts = createAsyncThunk('data/fetchContacts', async () => {
  try {
    const response = await axios.get(`${API_URL}/api/contacts/`)
    return response.data
  } catch (error) {
    console.error(error);
    toast.error(err.response?.data?.message || 'Contacts fetch failed.')
  }
})