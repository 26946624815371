import * as React from 'react';
import { createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { Outlet } from 'react-router-dom';
import { useNavigate, useLocation } from 'react-router-dom'
import jwtDecode from 'jwt-decode';
import Header from './Components/Header';
import Menu from './Components/Menu';
import Cookies from 'universal-cookie';
import { useDispatch } from 'react-redux';
import { login } from 'src/store/mainReducer';
import { fetchContacts, fetchPayments, fetchProfiles, fetchUsers } from 'src/store/data/dataAsyncThunks';
import config from 'config'
import ThemeProvider from 'src/theme';

import GlobalStyles from '../theme/globalStyles';
const drawerWidth = 240;



const mdTheme = createTheme({
  components: {
    palette: {
      primary: {
        main: config['primary-colour'],
      },
      success: {
        main: '#00ab55',
      }
      
  },
    MuiButton: {
      styleOverrides: {
        root: {
          // Custom styles for buttons
          textTransform: 'none', // Example of removing text transform
        },
        containedPrimary: {
          // Styles specifically for contained primary buttons
          backgroundColor: config['primary-colour']
        },
        outlinedPrimary: {
          borderColor: config['primary-colour']
        }
      },
    },
  }
});

function Layout() {
  const [openMenu, setOpenMenu] = React.useState(true);
  const dispatch = useDispatch()

  React.useEffect(() => {
    const cookies = new Cookies()
    const token = cookies.get('admin_token')
    if(token){
      try {
        const decoded = jwtDecode(token)
        if(decoded._id) {
          dispatch(login(decoded))
          dispatch(fetchUsers())
          dispatch(fetchProfiles())
          dispatch(fetchPayments())
          dispatch(fetchContacts())
        }
        
      } catch (error) {
        console.log(error);
      }
    }
  }, [dispatch])
  return (
<ThemeProvider>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <Header open={openMenu} setOpen={setOpenMenu} />
        <Menu open={openMenu}/>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto'
          }}
        >
          <Toolbar />
          <Container maxWidth="false" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                  <Outlet />
                </Paper>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Box>
      </ThemeProvider>
  );
}

export default Layout;