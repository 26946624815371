import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Link } from 'react-router-dom';
import SupervisorAccountRoundedIcon from '@mui/icons-material/SupervisorAccountRounded';
import AlternateEmailRoundedIcon from '@mui/icons-material/AlternateEmailRounded';
import PaymentsTwoToneIcon from '@mui/icons-material/PaymentsTwoTone';
import sd from '../../assets/logos/sciencedirectory-logo-icon.png';
import md from '../../assets/logos/md-logo-icon.png';
import ed from '../../assets/logos/englishdirectory-logo-icon.png';
import config from 'config';

const MenuItems = () => {
  let links = [];

  if (config['class-name'] === 'math' || config['class-name'] === 'english') {
    links.push({
      name: 'Science Directory',
      link: 'https://admin.sciencedirectory.co.uk',
      logo: sd,
    });
  }

  if (config['class-name'] === 'science' || config['class-name'] === 'english') {
    links.push({
      name: 'Maths Directory',
      link: 'https://admin.mathsdirectory.co.uk',
      logo: md,
    });
  }

  if (config['class-name'] === 'science' || config['class-name'] === 'math') {
    links.push({
      name: 'English Directory',
      link: 'https://admin.englishdirectory.co.uk',
      logo: ed,
    });
  }
  return (
    <>
      <Link to="/user">
        <ListItemButton>
          <ListItemIcon>
            <SupervisorAccountRoundedIcon />
          </ListItemIcon>
          <ListItemText primary="Users" />
        </ListItemButton>
      </Link>
      {/* <Link to="/teacher">
      <ListItemButton>
        <ListItemIcon>
          <Groups2RoundedIcon />
        </ListItemIcon>
        <ListItemText primary="Teachers" />
      </ListItemButton>
    </Link> */}
      <Link to="/payment">
        <ListItemButton>
          <ListItemIcon>
            {/* <ForumIcon /> */}
            <PaymentsTwoToneIcon />
          </ListItemIcon>
          <ListItemText primary="Payments" />
        </ListItemButton>
      </Link>
      <Link to="/contact">
        <ListItemButton>
          <ListItemIcon>
            <AlternateEmailRoundedIcon />
          </ListItemIcon>
          <ListItemText primary="Contact Us" />
        </ListItemButton>
      </Link>
      {links.map((link) => {
        return (
          <Link to={link.link} target="_blank">
            <ListItemButton>
              <ListItemIcon>
                <img src={link.logo} alt={link.name} style={{ width: '24px', height: '100%' }} />
              </ListItemIcon>
              <ListItemText primary={link.name} />
            </ListItemButton>
          </Link>
        );
      })}
    </>
  );
};

export default MenuItems;
