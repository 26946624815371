export const getStatus = (isGuest, isAdmin, userStatus, profileExist, profileStatus, isSubmitted) => {
  if(isAdmin) {
    return {
      profile : 'Approved',
      profileStatus:'approved',
      profileColor : 'success',
      showActivate : false
    }
  }
  if(userStatus < 0) {
    return {
      profile : 'Not verified',
      profileStatus:'not-verified',
      profileColor : 'primary',
      showActivate : false
    }
  }
  if(userStatus == 2) {
    return {
      profile : 'Disabled',
      profileStatus:'disabled',
      profileColor : 'error',
      showActivate : true
    }
  }
  if(isGuest) {
    if(userStatus == 0) {
      return {
        profile : 'Pending approval',
        profileStatus:'pending',
        profileColor : 'warning',
        showActivate : true
      }
    } else {
      return {
        profile : 'Approved',
        profileStatus:'approved',
        profileColor : 'success',
        showActivate : true
      }
    }
  }
  if(userStatus === 1 && profileStatus === 1) {
    return {
      profile : 'Approved',
      profileStatus:'approved',
      profileColor : 'success',
      showActivate : true
    }
  }
  else if(userStatus === 0 || profileStatus === 0) {
    if(isSubmitted) {
      return {
        profile : 'Pending approval',
        profileStatus:'pending',
        profileColor : 'warning',
        showActivate : true
      }
    } else {
      return {
        profile : 'Draft',
        profileStatus:'draft',
        profileColor : 'primary',
        showActivate : profileExist
      }
    }
  } else {
    return {
      profile : 'Draft',
      profileStatus:'draft',
      profileColor : 'primary',
      showActivate : false
    }
  }
}