import { Navigate, useRoutes } from 'react-router-dom';
import Cookies from 'universal-cookie';

import UserPage from './pages/users/UserPage';
import TeacherPage from './pages/teacher/TeacherPage';
import ContactPage from './pages/contact/ContactPage';

import LoginPage from './pages/login/LoginPage';
import Page404 from './pages/Page404';
import Layout from './layouts/Layout';
import NewTeacher from './pages/teacher/NewTeacher';
import UpdateTeacher from './pages/teacher/UpdateTeacher';
import UpdateUser from './pages/users/UpdateUser';
import ContactDetails from './pages/contact/ContactDetails';
import AddNewUser from './pages/users/AddNewUser';
import ChangePassword from './pages/ChangePassword';
import Payments from './pages/payment/Payments';
import AddNewPayment from './pages/payment/AddNewPayment';
import UpdateProfile from './pages/users/UpdateProfile';
import { UpdateUserProfile } from './pages/users/UpdateUserProfile';

// ----------------------------------------------------------------------



export default function Router() {
  const cookies = new Cookies();
  const token = cookies.get('admin_token');

  const routes = useRoutes([
    {
      path: '/',
      element: token ? <Layout /> : <Navigate to="/login" />,
      children: [
        { element: <Navigate to="/user" />, index: true },
        {
          path: '/user',
          element: <UserPage />,
        },
        {
          path: '/user/new',
          element: <AddNewUser />,
        },
        {
          path: '/user/:id',
          element: <UpdateUser />,
        },
        {
          path: '/user/profile/:id',
          element: <UpdateProfile />,
        },
        {
          path: '/user/update/:id',
          element: <UpdateUserProfile />,
        },
        {
          path: '/changePassword',
          element: <ChangePassword />,
        },
        {
          path: '/payment',
          element: <Payments />,
        },
        {
          path: '/payment/new',
          element: <AddNewPayment />,
        },
        {
          path: '/contact',
          element: <ContactPage />,
        },
        {
          path: '/contact/:id',
          element: <ContactDetails />,
        },
      ],
    },
    {
      path: '/login',
      element: token ? <Navigate to="/user" /> : <LoginPage />,
    },,
    {
      element: <Layout />,
      children: [
        { element: <Navigate to="/user" />, index: true },
        { path: '*', element: <Navigate to="/" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/" replace />,
    },
  ]);

  return routes;
}
