import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import ScrollToTop from './components/scroll-to-top';
import { useEffect } from 'react';


import { useDispatch } from 'react-redux';
import { login } from './store/mainReducer';
import { fetchContacts, fetchPayments, fetchProfiles, fetchUsers } from './store/data/dataAsyncThunks';

// ----------------------------------------------------------------------

export default function App() {
  

  return (
    <ThemeProvider>
      <ScrollToTop />
      <Router />
      <ToastContainer
        position="top-right"
        autoClose={1000}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </ThemeProvider>
  );
}
