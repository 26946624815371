import { createSlice } from '@reduxjs/toolkit';
import { fetchUsers, fetchProfiles, fetchPayments, fetchContacts } from './dataAsyncThunks';

const initialState = {
  users: [],
  filteredUsers: null,
  profiles: [],
  filteredProfiles: null,
  payments: [],
  contacts: [],
  filterBySearch: '',
  filterByStatus: 'null',
  filterByAcountType: 'null'
}

const mainSlice = createSlice({
  name: 'data',
  initialState,
  reducers: {
    setUsers: (state, action) => {
      state.users = action.payload
    },
    setPayments: (state, action) => {
      state.payments = action.payload
    },
    setContacts: (state, action) => {
      state.contacts = action.payload
    },
    setProfiles: (state, action) => {
      state.profiles = action.payload
    },
    setFilteredUsers: (state, action) => {
      state.filteredUsers = action.payload
    },
    setFilteredProfiles: (state, action) => {
      state.filteredProfiles = action.payload
    },
    setFilteredProfilesAndUsersEmpty: (state) => {
      state.filteredUsers = null
      state.filteredProfiles = null
    },

    setSearchTextFilter: (state, action) => {
      state.filterBySearch = action.payload
    },
    setStatusFilterGlobally: (state, action) => {
      state.filterByStatus = action.payload
    },
    setAccountTypeFilterGlobally: (state, action) => {
      state.filterByAcountType = action.payload
    },
    setFiltersEmpty: (state) => {
      state.filterBySearch = ""
      state.filterByStatus = "null"
      state.filterByAcountType = "null"
    }

  },
  extraReducers: builder =>
    builder.addCase(fetchUsers.fulfilled, (state, action) => {
      state.users = action.payload
    }).addCase(fetchProfiles.fulfilled, (state, action) => {
      state.profiles = action.payload
    }).addCase(fetchPayments.fulfilled, (state, action) => {
      state.payments = action.payload
    }).addCase(fetchContacts.fulfilled, (state, action) => {
      state.contacts = action.payload
    })
});

export const { setUsers, setContacts, setPayments, setProfiles, setFilteredUsers, setFilteredProfiles, setAccountTypeFilterGlobally, setFilteredProfilesAndUsersEmpty, setSearchTextFilter, setStatusFilterGlobally, setFiltersEmpty } = mainSlice.actions;

export default mainSlice.reducer;