import React from 'react';
// import themeConfig from '@configs/themeConfig';
import './app-loader.css';
import CircularProgress from '@mui/material/CircularProgress';

export const LoaderComponent = () => {
  return (
    <div className="loader-component">
      <div
        id="loader"
        className="fallback-spinner app-loader"
        style={{
          display: 'none',
          width: '100vw',
          position: 'absolute',
          backgroundColor: '#F8F8F899',
          zIndex: 1000000,
        }}
      >
        <CircularProgress variant="indeterminate" />
      </div>
    </div>
  );
};

var pendingRequest = 0;
const showLoader = (loading) => {
  const loader = document.getElementById('loader');
  if (loader) {
    if (loading === true) {
      pendingRequest++;
      loader.style.display = '';
    } else {
      pendingRequest--;
      if (pendingRequest <= 0) {
        loader.style.display = 'none';
      }
    }
  }
};

export default showLoader;
