import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import React from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
// mock
import { API_URL } from '../../api';
import showLoader from 'src/components/loader';
import { useDispatch, useSelector } from 'react-redux';
import { setUsers } from 'src/store/data/dataReducer';

export const DeleteModal = ({ deleteModal, handleDeleteModal, deleteId}) => {
  const dispatch = useDispatch()
  const { users } = useSelector(state => state.data)
  // ** Function to delete single role
  const deleteUser = async (id) => {
    showLoader(true);
    await axios
      .delete(`${API_URL}/api/auth/${id}`)
      .then(
        (res) => {
          toast.success(res.data?.message || 'User deleted successfully.');
          dispatch(setUsers(users.filter(user => user._id !== deleteId)));
          handleDeleteModal();
        }
      ).catch(err => {
        console.log(err);
        toast.error(err.data?.message || 'User delete failed.');
      }).finally(() => {
        showLoader(false);
      });
    
  };

  return (
    <Dialog open={deleteModal}>
      <DialogTitle>Delete User</DialogTitle>
      <DialogContent dividers>
        <Typography variant="subtitle1">Are you sure? </Typography>
        <Typography variant="subtitle1">Once deleted data cannot be recovered.</Typography>
      </DialogContent>

      <DialogActions>
        <Button
          variant="contained"
          type="submit"
          style={{ fontFamily: 'Open Sans', minWidth: '90px' }}
          onClick={() => {
            deleteUser(deleteId);
          }}
        >
          Confirm
        </Button>
        <Button
          variant="contained"
          type="submit"
          style={{ fontFamily: 'Open Sans', minWidth: '90px' }}
          onClick={handleDeleteModal}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};
