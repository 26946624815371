import { Alert, Box, Button, ButtonGroup, Container, Modal, Stack, TextField, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import Cookies from 'universal-cookie';
import jwtDecode from 'jwt-decode';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { API_URL } from '../api';
import { toast } from 'react-toastify';
import showLoader from 'src/components/loader';

function ChangePassword() {
  const classes = useStyles();
  const navigate = useNavigate();
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');

  const updatePassword = async ()=> {
    showLoader(true);
    await axios
      .patch(
        `${API_URL}/api/auth/changePassword`,
        { oldPassword, newPassword }
      )
      .then((res) => {
        toast.success('Password changed successfully.')
        navigate('/user');
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response?.data?.message || 'Password change failed.')
      });
    showLoader(false);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (oldPassword === '') {
      toast.error('Old Password is required.');
      return;
    }
    if (newPassword === '') {
      toast.error('New Password is required.');
      return;
    }
    updatePassword();
  };

  const back = (e) => {
    e.preventDefault();
    navigate('/user');
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: '#FDEDED',
    boxShadow: 24,
    borderRadius: '5px',
  };

  return (
    <Container maxWidth="false">
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" gutterBottom sx={{ fontFamily: 'Open Sans', fontWeight: '600' }}>
          Change Password
        </Typography>
      </Stack>
      <Box
        component="form"
        className={classes.form}
        onSubmit={handleSubmit}
        sx={{
          '& > :not(style)': { m: 1 },
        }}
      >
        <TextField
          className={classes.formFields}
          value={oldPassword}
          onChange={(e) => setOldPassword(e.target.value)}
          label="Old Password"
          type="text"
          margin="normal"
          fullWidth
        />
        <TextField
          className={classes.formFields}
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          label="New Password"
          type="text"
          margin="normal"
          fullWidth
        />
        <ButtonGroup className={classes.buttons} aria-label="outlined primary button group">
          <Button onClick={(e) => back(e)} style={{ fontFamily: 'Open Sans', minWidth: '90px' }}>
            Back
          </Button>
          <Button variant="contained" type="submit" style={{ fontFamily: 'Open Sans', minWidth: '90px' }}>
            Update
          </Button>
        </ButtonGroup>
      </Box>
    </Container>
  );
}

export default ChangePassword;

const useStyles = makeStyles((theme) =>
  createStyles({
    form: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    formFields: {
      margin: '10px',
      [theme.breakpoints.down('sm')]: {
        flexBasis: '90%',
      },
    },
    textEditor: {
      flexBasis: '91.5%',
      [theme.breakpoints.down('sm')]: {
        flexBasis: '90%',
      },
    },
    buttons: {
      flexBasis: '100%',
      justifyContent: 'flex-end',
    },
  })
);
