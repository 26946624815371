import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import React from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
// mock
import { API_URL } from '../../api';
import showLoader from 'src/components/loader';
import { useDispatch, useSelector } from 'react-redux';
import { setPayments, setUsers } from 'src/store/data/dataReducer';

export const DeleteModal = ({ deleteModal, handleDeleteModal, deleteId}) => {
  const dispatch = useDispatch()
  const { payments, users } = useSelector(state => state.data)

  // ** Function to delete single role
  const deletePayment = async (id) => {
    showLoader(true);
    await axios
      .delete(`${API_URL}/api/billings/${id}`)
      .then(
        (res) => {
          toast.success(res.data?.message || 'Payment deleted successfully.');
          const paymentIndex = payments.findIndex(payment => payment._id === deleteId)
          const userId = paymentIndex > -1 ? payments[paymentIndex].user?._id : null
          const newPayments = payments.filter(payment => payment._id !== deleteId)
          let billedAt = null
          if(userId) {
            const usersPayments = newPayments.filter(payment => payment.user?._id === userId)
            usersPayments.forEach(payment => {
              if(!billedAt || billedAt < payment.createdAt) billedAt = payment.createdAt
            });
          }
          const userIndex = users.findIndex(user => user._id === userId)
          if(userIndex > -1){
            let newUser = {...users[userIndex]}
            newUser.billedAt = billedAt
            let newUsers = [...users]
            newUsers.splice(userIndex, 1, newUser)
            dispatch(setUsers(newUsers))
          }
          dispatch(setPayments(newPayments));
          handleDeleteModal();
        }
      ).catch(err => {
        console.log(err);
        toast.error(err.response?.data?.message || 'Payment delete failed.');
      }).finally(() => {
        showLoader(false);
      });
  };

  return (
    <Dialog open={deleteModal}>
      <DialogTitle>Delete Payment</DialogTitle>
      <DialogContent dividers>
        <Typography variant="subtitle1">Are you sure? </Typography>
        <Typography variant="subtitle1">Once deleted data cannot be recovered.</Typography>
      </DialogContent>

      <DialogActions>
        <Button
          variant="contained"
          type="submit"
          style={{ fontFamily: 'Open Sans', minWidth: '90px' }}
          onClick={() => {
            deletePayment(deleteId);
          }}
        >
          Confirm
        </Button>
        <Button
          variant="contained"
          type="submit"
          style={{ fontFamily: 'Open Sans', minWidth: '90px' }}
          onClick={handleDeleteModal}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};
