import { Button, ButtonGroup, Card, Container, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { API_URL } from '../../api';
import { useSelector } from 'react-redux';

function ContactDetails() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { contacts } = useSelector()

  const contact = contacts.find(contact = contact._id === id)

  const back = (e) => {
    e.preventDefault();
    navigate('/childProfile');
  };

  return (
    <Container maxWidth="false">
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" gutterBottom sx={{ fontFamily: 'Open Sans', fontWeight: '600' }}>
          Details
        </Typography>
      </Stack>
      <Card sx={{ p: 4, display: 'flex', flexDirection: 'column', borderRadius: '12px' }}>
        <Typography
          variant="h6"
          sx={{
            fontFamily: 'Open Sans',
            fontWeight: '500',
            margin: '3px',
            padding: '8px',
            border: '1px solid whitesmoke',
            borderRadius: '10px',
          }}
        >
          Name: <span style={{ fontWeight: '400', fontSize: '17px' }}> {contact?.fullName}</span>
        </Typography>

        <Typography
          variant="h6"
          sx={{
            fontFamily: 'Open Sans',
            fontWeight: '500',
            margin: '3px',
            padding: '8px',
            border: '1px solid whitesmoke',
            borderRadius: '10px',
          }}
        >
          Email: <span style={{ fontWeight: '400', fontSize: '17px' }}> {contact?.email}</span>
        </Typography>
        <Typography
          variant="h6"
          sx={{
            fontFamily: 'Open Sans',
            fontWeight: '500',
            margin: '3px',
            padding: '8px',
            border: '1px solid whitesmoke',
            borderRadius: '10px',
          }}
        >
          Message: <br/>
          <pre style={{ fontWeight: '400', fontSize: '17px', width: '100%' }}> {contact?.message}</pre>
        </Typography>
      </Card>
      <ButtonGroup aria-label="outlined primary button group">
        <Button style={{ marginTop: '20px', minWidth: '90px' }} onClick={(e) => back(e)}>
          Back
        </Button>
      </ButtonGroup>
    </Container>
  );
}

export default ContactDetails;
