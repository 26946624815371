import * as React from 'react';
import { styled } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import MenuItems from './MenuItems';
import config from 'config';
const drawerWidth = 240;

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      backgroundColor: 'white',
      boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),

      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
    '& .MuiTypography-root': {
      color: 'gray',
      fontFamily: 'Open Sans',
      fontSize: '17px',
      fontWeight: '400',
    },

  }),
);


function Menu({ open }) {
  let logoUrl = '/assets/logo.svg';
  if(config['class-name'] === "english") {
    logoUrl = '/assets/english-logo.svg';
  } else if (config['class-name'] === 'science') {
    logoUrl = '/assets/science-logo.svg';
  }
  return (
    <Drawer variant="permanent" open={open}>
      <Toolbar
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          p: 1,
        }}
      >
        <img src={logoUrl} height={60} alt='Logo' />
      </Toolbar>
      <List component="nav">
        <MenuItems/>
      </List>
    </Drawer>
  );
}

export default Menu;