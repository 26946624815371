import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Button, ButtonGroup, Container, FormControl, Stack, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import { API_URL } from '../../api';
import axios from 'axios';
import UploadAvatar from '../../components/UploadAvatar/UploadAvatar';
import { toast } from 'react-toastify';

export const UpdateUserProfile = () => {

  const { id: userId } = useParams();

  const { users, profiles } = useSelector((state) => state.data);
  const user = users.find((user) => user._id === userId);
  const [firstName, setFirstName] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const [phone, setPhone] = React.useState('');
  const [avatar, setAvatar] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      setFirstName(user.firstName);
      setLastName(user.lastName);
      setPhone(user.guest?.phone || '');
      setAvatar(user.guest?.avatar || '');
    }
  }, [user]);

  const handleSubmit = (e) => {
    e.preventDefault();
    axios.post(`${API_URL}/api/guests/update-admin`, {
      firstName,
      lastName,
      phone,
      id: userId,
      avatar,
    }).then((response) => {
      navigate('/user');
    }).catch(() => {
      toast.error('Error updating profile');
    })
  };

  const back = (e) => {
    e.preventDefault();
    navigate('/user');
  };

  return (
    <Container maxWidth="false">
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" gutterBottom sx={{ fontFamily: 'Open Sans', fontWeight: '600' }}>
          Update Profile
        </Typography>
      </Stack>
      <Box
        component="form"
        onSubmit={handleSubmit}
        fullWidth
      >
        <Grid container spacing={2}>
          <Grid xs={12}>
            <FormControl fullWidth style={{ display: 'flex', justifyContent: 'center', flexDirection: 'row'}}>
              <div style={{ height: '200px', width: '200px' }}>
                <UploadAvatar avatar={avatar} setAvatar={setAvatar} userId={userId} />
              </div>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              fullWidth
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              label="First Name"
              type="text"
              margin="normal"
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              fullWidth
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              label="Last Name"
              type="text"
              margin="normal"
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              fullWidth
              margin="normal"
              label="Phone"
              variant="outlined" placeholder="Enter your phone" type="number"
              onKeyDown={e => (e.keyCode === 69 || e.keyCode === 190) && e.preventDefault()}
              value={phone} onChange={(e) => setPhone(e.target.value)} />
          </Grid>
        </Grid>
        <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%', marginTop:'10px' }}>
          <ButtonGroup aria-label="outlined primary button group">
            <Button onClick={(e) => back(e)} style={{ fontFamily: 'Open Sans', minWidth: '90px' }}>
              Back
            </Button>
            <Button variant="contained" type="submit" style={{ fontFamily: 'Open Sans', minWidth: '90px' }}>
              Submit
            </Button>
          </ButtonGroup>
        </div>
      </Box>
    </Container>
  );
};