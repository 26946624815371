import * as React from 'react';
import { styled } from '@mui/material/styles';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import moment from 'moment';
import AccountPopover from './AccountPopover';
import { useEffect, useState } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Box } from '@mui/material';
const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  backgroundColor: 'white',

  // boxShadow: 'none',
  boxShadow: 'rgba(0, 0, 0, 0.1) 4px 4px 12px',

  // borderRadius:'20px',

  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

function Header({open, setOpen}) {

  const [currentTime, setCurrentTime] = useState(moment().format("MMM Do, h:mm a"));
  const [showMenuButton, setShowMenuButton] = useState(false);
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('md'));

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(moment().format("MMM Do, h:mm a"));
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const toggleDrawer = () => {
    setOpen(!open);
  };


  useEffect(() => {
    if (isSmallScreen) {
      setShowMenuButton(true)
      setOpen(false)
    } else {
      setShowMenuButton(false)
      setOpen(true)
    }
  }, [isSmallScreen]);

  return (
    <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: '24px', // keep right padding when drawer closed
              justifyContent: 'space-between'
            }}
          >
            <Box>
            {
              !showMenuButton && <IconButton
                edge="start"
                color="default"
                aria-label="open drawer"
                onClick={toggleDrawer}
                sx={{
                  marginRight: '36px',
                  padding: '4px',
                  // ...(open && { display: 'none' }),
                }}
              >
                <MenuIcon />
              </IconButton>
            }
            </Box>

            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Typography
                color="#707070"
                sx={{
                  display: 'flex',
                  marginRight: '20px',
                  fontFamily: 'Open Sans',
                  fontSize: '17px',
                }}
              >
                <AccessTimeIcon style={{ marginRight: '10px' }} />{' '}
                {currentTime}
              </Typography>
              <AccountPopover />
            </div>
          </Toolbar>
    </AppBar>
  );
}

export default Header;