import React from 'react';
import { FormLabel, Slider, FormControl } from '@mui/material';

const PriceSlider = (props) => {
  const { price, setPrice} = props
  //----------- price -------------
  const minDistance = 0;
  const priceMarks = [
    {
      value: 15,
      label: '\u00A315',
    },
    {
      value: 100,
      label: '\u00A3100+',
    },
  ];

  const setNewPrice = (event, newPrice, activeThumb) => {
    if (!Array.isArray(newPrice)) {
      return;
    }

    if (activeThumb === 0) {
      setPrice([Math.min(newPrice[0], price[1] - minDistance), price[1]]);
    } else {
      setPrice([price[0], Math.max(newPrice[1], price[0] + minDistance)]);
    }
  }

  const valuetext = value => `\u00A3${value}`;

  return (
    <FormControl fullWidth>
      <FormLabel id="price-price" style={{color: 'black'}}><strong>Price</strong> : {valuetext(price[0])} - {price[1] && price[1] === 100 ? (price[1] + '+') : price[1] }</FormLabel>
      <Slider
        getAriaLabel={() => 'Minimum distance'}
        value={price}
        onChange={setNewPrice}
        valueLabelDisplay="auto"
        getAriaValueText={valuetext}
        marks={priceMarks}
        min={15}
        max={100}
        disableSwap
        aria-labelledby="price-price"
      />
    </FormControl>)
};

export default PriceSlider;
