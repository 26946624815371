import PropTypes from 'prop-types';
// @mui
import axios from 'axios';
import { styled, alpha } from '@mui/material/styles';
import { Toolbar, Tooltip, IconButton, Typography, OutlinedInput, InputAdornment } from '@mui/material';
// component
import { API_URL } from '../../../api';
import Iconify from '../../../components/iconify';
import Cookies from 'universal-cookie';
import { useDispatch } from 'react-redux';
import { fetchContacts } from 'src/store/data/dataAsyncThunks';
const cookies = new Cookies();

// ----------------------------------------------------------------------
const StyledRoot = styled(Toolbar)(({ theme }) => ({
  height: 90,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3),
}));

const StyledSearch = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  borderRadius: '30px',
  maxHeight: 50,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': {
    width: 320,
    boxShadow: theme.customShadows.z8,
  },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${alpha(theme.palette.grey[500], 0.32)} !important`,
  },
}));
// ----------------------------------------------------------------------

ContactListToolbar.propTypes = {
  numSelected: PropTypes.number,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func,
  selected: PropTypes.array,
  setContact: PropTypes.func,
};

export default function ContactListToolbar({ numSelected, filterName, onFilterName, selected}) {
  const dispatch = useDispatch()
  const deleteMany = () => {
    axios
      .delete(`${API_URL}/api/contacts/${selected.join(',')}`)
      .then(() => {
        dispatch(fetchContacts())
      })
      .catch((err) => console.log(err));
  };
  return (
    <StyledRoot
      sx={{
        ...(numSelected > 0 && {
          color: 'primary.main',
          bgcolor: 'primary.lighter',
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography component="div" variant="subtitle1">
          {/* {numSelected} selected */}
        </Typography>
      ) : (
        <StyledSearch
          value={filterName}
          onChange={onFilterName}
          placeholder="Search a Name..."
          startAdornment={
            <InputAdornment position="start">
              <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
            </InputAdornment>
          }
        />
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton onClick={() => deleteMany(selected)}>
            <Iconify icon="eva:trash-2-fill" />
          </IconButton>
        </Tooltip>
      ) : (
        ''
      )}
    </StyledRoot>
  );
}
