import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Cookies from 'universal-cookie';
import jwtDecode from "jwt-decode";

const initialState = {
  _id: null,
  email: '',
  firstName: '',
  lastName: '',
}

const mainSlice = createSlice({
  name: 'main',
  initialState,
  reducers: {
    login: (state, action) => {
      return {...state, ...action.payload}
    },
    logout: () => {
      const cookies = new Cookies();
      console.log('cookies', cookies);
      cookies.remove('admin_token')
      return initialState
    },
    getCurrentUser: (state) => {
      const cookies = new Cookies();
      const token = cookies.get('admin_token');
      if (token) {
        try {
          const decodedToken = jwtDecode(token);
          const {createdAt, billedAt, _id : userId, email, firstName, lastName} = decodedToken
          return {...state, userId, email, createdAt, billedAt, firstName, lastName}
        } catch (error) {
          console.log(error);
         return initialState
        }
      } else return initialState
    }
  }
});

export const { login, logout, getCurrentUser } = mainSlice.actions;

export default mainSlice.reducer;