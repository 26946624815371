import { configureStore } from '@reduxjs/toolkit';
import mainReducer from './mainReducer'; // Import your root reducer
import dataReducer from './data/dataReducer';

const store = configureStore({
  reducer: { 
    main: mainReducer,
    data: dataReducer
  },
});

export default store;