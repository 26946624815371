import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
import { sentenceCase } from 'change-case';
import Title from '../../../src/components/title/Title'
// @mui
import {
  Card,
  Table,
  Stack,
  Avatar,
  Checkbox,
  Popover,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  IconButton,
  Typography,
  TableContainer,
  TablePagination,
  Button,
} from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
// components
import axios from 'axios';

import Label from '../../components/label';
import Iconify from '../../components/iconify';
import Scrollbar from '../../components/scrollbar';
// sections
import { UserListHead } from '../../sections/@dashboard/user';
import { API_URL } from '../../api';
import { DeleteModal } from './DeleteModal';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { setPayments } from 'src/store/data/dataReducer';
import { fetchPayments } from 'src/store/data/dataAsyncThunks';
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'email', label: 'Email', alignRight: false },
  { id: 'date', label: 'Date', alignRight: false},
  { id: 'status', label: 'Status', alignRight: false },
  { id: 'actions', label: 'Actions', alignRight: true },
];

// ----------------------------------------------------------------------

export default function Payments() {
  const dispatch = useDispatch()
  const { payments } = useSelector(state => state.data)

  const [open, setOpen] = useState(null);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [currentId, setCurrentId] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const displayData = payments.slice(rowsPerPage * page, rowsPerPage * (page + 1)).map(payment => {
      const { _id, firstName, lastName, email : paymentEmail, status, createdAt } = payment;
      const name = `${firstName || ''} ${lastName || ''}`
      const email = paymentEmail || ''
      const date = new Date(createdAt).toLocaleDateString('en-GB')
      return {
        _id,
        name,
        date,
        email,
        status
      }
  }).sort((a, b) => {
    const aValue = a[orderBy]
    const bValue = b[orderBy]
    return order === 'asc'
      ? aValue > bValue ? -1 : 1
      : aValue < bValue ? -1 : 1
  })

  const handleDeleteModal = (id) => {
    deleteId ? setDeleteId() : setDeleteId(id);
    setDeleteModal(!deleteModal);
  };

  const navigate = useNavigate();

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = users.map((n) => n._id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, userId) => {
    event.stopPropagation();
    const selectedIndex = selected.indexOf(userId);

    if (selectedIndex < 0) {
      // Add the userId to the selected array
      setSelected((prevSelected) => [...prevSelected, userId]);
    } else {
      // Remove the userId from the selected array
      setSelected((prevSelected) =>
        prevSelected.filter((id) => id !== userId)
      );
    }
  };

  const handleOpenMenu = (event, userId) => {
    setOpen(event.currentTarget);
    setCurrentId(userId);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - payments.length) : 0;

  const createNew = (e) => {
    e.preventDefault();
    navigate('/payment/new');
  };

  useEffect(() => {
      dispatch(fetchPayments())
  }, [dispatch])

  return (
    <>
      <Title text="Payments"/>

      <Container maxWidth="false" sx={{ paddingLeft: { xs: '0px', md: '16px' }, paddingRight: { xs: '0px', md: '16px' } }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom sx={{ fontSize: { xs: '24px', lg: '2.125rem' }, fontFamily: 'Open Sans', fontWeight: '600' }}>
            Payments
          </Typography>
          <Button
            onClick={(e) => createNew(e)}
            variant="contained"
            sx={{ padding: { xs: '8px 10px', md: '8px 22px' }, fontSize: { xs: '12px', md: '16px' }, borderRadius: '35px', fontFamily: 'Open Sans', fontWeight: '500' }}
            startIcon={<Iconify width={15} icon="eva:plus-fill" />}
          >
            Add Payment
          </Button>
        </Stack>

        <Card sx={{ borderRadius: '12px' }}>
          <Scrollbar>
            <TableContainer sx={{ overflow: 'visible' }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={payments.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {Array.isArray(displayData) &&
                    displayData?.map((payment, index) => {
                      const { _id, name, email, status, date } = payment;
                      const selectedPayment = selected.findIndex(selectedId => selectedId === _id) > -1

                      return (
                        <TableRow hover key={index} tabIndex={-1} role="checkbox" selected={selectedPayment}>
                          <TableCell padding="checkbox">
                            <Checkbox checked={selectedPayment} onChange={(event) => handleClick(event, _id)} />
                          </TableCell>
                          <TableCell component="th" scope="row" padding="none">
                            <strong>{name}</strong>
                          </TableCell>
                          <TableCell align="left" sx={{ fontFamily: 'Open Sans' }}>
                            {email}
                          </TableCell>
                          <TableCell align="left" sx={{ fontFamily: 'Open Sans' }}>
                            {date}
                          </TableCell>
                          <TableCell align="left">
                            <Label
                              color={status ? 'primary' : 'error'}
                              sx={{ fontFamily: 'Open Sans' }}
                            >
                              {status ? 'Paid' : 'Disputed'}
                            </Label>
                          </TableCell>
                          
                          <TableCell align="right">
                            <IconButton size="large" color="inherit" onClick={(e) => handleOpenMenu(e, _id)}>
                              <Iconify icon={'eva:more-vertical-fill'} />
                            </IconButton>
                          </TableCell>
                          <Popover
                            open={Boolean(open)}
                            anchorEl={open}
                            onClose={handleCloseMenu}
                            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                            PaperProps={{
                              sx: {
                                p: 1,
                                width: 140,
                                '& .MuiMenuItem-root': {
                                  px: 1,
                                  typography: 'body2',
                                  borderRadius: 0.75,
                                },
                              },
                            }}
                            onClick={handleCloseMenu}
                          >
                            {/* <Link to={`/user/updateUser/${currentId}`}>
                              <MenuItem sx={{ fontFamily: 'Open Sans' }}>
                                <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
                                Edit
                              </MenuItem>
                            </Link> */}
                            <MenuItem
                              onClick={() => handleDeleteModal(currentId)}
                              sx={{ color: 'error.main', fontFamily: 'Open Sans' }}
                            >
                              <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
                              Delete
                            </MenuItem>
                          </Popover>
                        </TableRow>
                      );
                    })}
                  {displayData.length === 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} style={{textAlign: "center"}}><em>No Payment</em></TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={payments?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
        <DeleteModal
          deleteModal={deleteModal}
          handleDeleteModal={handleDeleteModal}
          deleteId={deleteId}
          payments={payments}
          setPayments={setPayments}
        />
      </Container>
    </>
  );
}
